$ki-mint: #D9F8F8;
//$ki-mint: #cfffe6;
$ki-petrol: #00496B;
$ki-rose: #A677A2;
$ki-violet: #2A0048;

//$synchron-blue-light: #cfffe6;
$synchron-blue-dark: $ki-petrol;
$synchron-blue-light: $ki-mint;
//$synchron-blue-dark: #023a7f;
$synchron-green: mix(#000, $ki-mint, 20%);

$navbar-background: $ki-mint;
$navbar-link-color: $ki-petrol;
$navbar-link-active-color: $ki-violet;

$breadcrumb-last-color: $ki-violet;

$boxes-hover-background: $ki-mint;
$boxes-hover-text: $ki-petrol;

$tint-active-inverse: $ki-petrol;

@import 'app';

.pagination li.active {
  background-color: $ki-mint;

  a {
    color: $ki-petrol;
  }
}

#profile-left {
  &:not(.child) {
    .cell > * {
      color: $ki-petrol;
    }
  }

  &.child {
    background-color: $ki-petrol;

    a {
      @include animated-underline($synchron-white, 1px);

      color: $synchron-white;
    }
  }
}

.box-holder.new-holder .box .content .add {
  background-color: $ki-mint;

  i {
    background-color: $ki-mint;
    color: $ki-petrol;
  }
}

hr {
  color: $ki-petrol;
  background-color: $ki-petrol;
}

#register {
  #license-type-wrapper {
    select {
      position: absolute;
    }

    ul {
      li:not(.disabled) {
        span {
          color: $ki-petrol !important;
        }
      }
    }
  }
}

.toast {
  background-color: $ki-violet;
  color: $synchron-white;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-content li {
  > a,
  > span {
    color: $ki-violet;
  }
}

#search-wrapper {
  .secondary-content {
    .btn {
      color: $synchron-white;
    }
  }
}

.picker__date-display,
.picker__day.picker__day--today {
  color: $ki-petrol;
}

.picker__year-display {
  color: rgba($ki-petrol, 0.7);
}

.picker__day--selected {
  background-color: $ki-petrol !important;
}
